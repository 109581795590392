import '@/polyfills';
import '@/handlers';
import '@/config';

import map from 'interactive-staticfile'
import htmlComponent from 'htmlcomponent'

htmlComponent.setStaticLoader(map)
htmlComponent.query(document)
htmlComponent.observe()
